const usPostalCodeRegex = /^\d{5}$|^\d{5}-\d{4}$/;
const caPostalCodeRegex = /^([a-ceghj-npr-tv-z]){1}[0-9]{1}[a-ceghj-npr-tv-z]{1}[- ]?[0-9]{1}[a-ceghj-npr-tv-z]{1}[0-9]{1}$/i;

function validatePostalCode(postalCode) {
	const validPostalRegex = [
		usPostalCodeRegex,
		caPostalCodeRegex,
	];

	return validPostalRegex.some((regex) => regex.test(postalCode));
}

module.exports = {
	postalCodeRegexByCountry: {
		us: usPostalCodeRegex,
		ca: caPostalCodeRegex,
	},
	validatePostalCode,
};